import React from "react";

import OtpInput from "react-otp-input";

import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Button, InputOTP } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  useVerifyEmailOtpMutation,
  useVerifyRegisteredUserMutation,
} from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";
// import { setAuthInfo } from "../../Data/Features/authSlice";
// import { IMAGES } from "../../Constant/ImageConstant";
// import { STRING } from "../../Constant/StringConstant";

export default function OTP({ setPage, email, afterRegister = false }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSetPassword = () => {
    setPage(ACCOUNT_ENUM.SET_PASSWORD);
  };

  const handleLogin = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const [
    verifyRegisteredUserByOTP,
    { isError, isLoading: isLoadingRegisterdUser, isSuccess },
  ] = useVerifyRegisteredUserMutation();

  const handleVerifyRegisteredUserOtp = async (data) => {
    data.email = await email;
    // data.device_type = "web";
    // data.device_token = 123;
    console.log(data);

    try {
      const response = await verifyRegisteredUserByOTP(data);

      console.log(response);

      if (response.error && response.error.status >= 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.success === true) {
        SuccessToast(response?.data?.message);
        handleLogin();
      }
    } catch (error) {
      console.log(error);
      Toast("Something went wrong.", "error");
    }
  };

  const [verifyForgetPasswordOtp, { isLoading: isLoadingForgotPassword }] =
    useVerifyEmailOtpMutation();

  const handleVerifyForgetPasswordOtp = async (data) => {
    data.email = await email;
    console.log(data);

    try {
      const response = await verifyForgetPasswordOtp(data);

      console.log(response);

      if (
        response.error &&
        response.error.status >= 400 &&
        response.error.status <= 499
      ) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
      }

      if (!response.error && response.data.success === true) {
        const token = response.data.data.api_token;

        // storeTokenForSetNewPassword(token);
        SuccessToast(response?.data?.message);
        handleSetPassword();
      }
    } catch (error) {
      console.log(error);
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("Something went wrong.", "error");
      }
    }
  };

  return (
    <AccountLayout
      // leftSideImage={IMAGES.LOGIN}
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.otp?.message
          ? Toast(errors?.otp?.message, "error")
          : null
        : ""}

      <div className="d-flex justify-content-center"></div>
      <br></br>

      {
        // <SubTitle text={STRING.VerifyOtp} classes="mb-1 akiraFont" />
        // <p>{STRING.ofYourAccount}</p>
      }

      <Form
        onSubmit={handleSubmit(
          afterRegister
            ? handleVerifyRegisteredUserOtp
            : handleVerifyForgetPasswordOtp
        )}
      >
        <Controller
          control={control}
          name="otp"
          rules={{
            required: "OTP is required.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <OtpInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => <InputOTP props={props} />}
            />
          )}
        />
        <Button
          text={
            isLoadingRegisterdUser || isLoadingForgotPassword
              ? "Verifying"
              : afterRegister
              ? "VerifyOtp"
              : "ForgotPassword"
          }
          classes="mt-5 mb-2"
          type="submit"
          disabled={isLoadingRegisterdUser || isLoadingForgotPassword}
        />
      </Form>
    </AccountLayout>
  );
}
