import { createSlice } from "@reduxjs/toolkit";
import { blogsApi } from "../services/blogsApi";

const initialState = {
  blogs: [],
};

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        blogsApi.endpoints.getAllBlogs.matchFulfilled,
        (state, { payload }) => {
          state.blogs = payload.data;
        }
      )
      .addMatcher(blogsApi.endpoints.getAllBlogs.matchRejected, (state) => {
        state.blogs = null;
      });
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } = blogsSlice.actions;

export default blogsSlice.reducer;
