import React from "react";
import { FaCircleXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const LogoutModal = ({ handleLogoutModalClose, data, onLogout }) => {
  return (
    <div>
      <ModalContainer handleClose={handleLogoutModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">Confirm Logout</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleLogoutModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div>
            <p>Do you want to logout ? </p>

            <div className="d-flex justify-content-center align-items-center">
              <div
                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-dark text-white"
                role="button"
                onClick={handleLogoutModalClose}
              >
                <span className={`mx-2 d-flex`}>Cancle</span>
              </div>

              <div
                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-danger"
                role="button"
                onClick={() => {
                  onLogout();
                }}
              >
                <span className={`mx-2 d-flex text-white`}>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default LogoutModal;
