import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ text, onChange }) => {
  // Use a ref to access the quill instance directly
  const quillRef = useRef();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  const handleChangeValue = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // get the Quill editor instance
      const currentText = editor.root.innerHTML; // get the HTML content
      console.log(currentText);
      onChange(currentText);
    }
  };

  // const modules = useMemo(
  //     () => ({
  //         toolbar: {
  //             container: [
  //                 [{ font: [] }],
  //                 [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //                 ["bold", "italic", "underline", "strike"],
  //                 [{ color: [] }, { background: [] }],
  //                 [{ script: "sub" }, { script: "super" }],
  //                 ["blockquote", "code-block"],
  //                 [{ list: "ordered" }, { list: "bullet" }],

  //                 [
  //                     { indent: "-1" },
  //                     { indent: "+1" },
  //                     { align: [] },
  //                 ],
  //                 [{ direction: "rtl" }],
  //                 [{ size: ["small", false, "large", "huge"] }],
  //                 // ["link", "image", "video"],
  //                 ["clean"],
  //             ],

  //             // handlers: {
  //             //   image: handleClick,
  //             // },
  //             history: {
  //                 delay: 500,
  //                 maxStack: 100,
  //                 userOnly: true,
  //             },
  //         },
  //     }),
  //     []
  // );

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={text}
      onChange={handleChangeValue}
      style={{ backgroundColor: "white", color: "black" }}
      // modules={modules}
    />
  );
};

export default TextEditor;
