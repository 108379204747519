import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import TextEditor from "../../Components/TextEditor/TextEditor";
import { useUpdateStaticContentMutation } from "../../Data/services/settingsApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

export default function RefundPolicy({ refetch }) {
  const [value, setValue] = useState(
    "<h1>Privacy Policy</h1><h2>What is Lorem Ipsum?</h2><p><br></p><p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p > <p><br></p>"
  );

  const [link, setLink] = useState("");

  const handleLinkChange = (e) => {
    console.log(e.target.value);
    setLink(e.target.value);
  };

  var pagesData = useSelector((state) => state.pages.pages);

  console.log(pagesData);

  const specificPage = pagesData.find((page) => page.slug === "refund_policy");

  console.log(specificPage);

  useEffect(() => {
    if (specificPage) {
      setValue(specificPage.content);
    }
  }, [specificPage]);

  const [updateContent] = useUpdateStaticContentMutation();

  const handleSaveText = async () => {
    console.log(specificPage.id);
    // console.log(value);
    // console.log(link);

    const id = specificPage.id;
    const modifiedData = await {
      content: value,
      url: link,
    };

    console.log(modifiedData);

    try {
      const response = await updateContent({ modifiedData, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };
  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="Refund Policy" classes="mb-4 primary-text" />
          <Input
            name="link"
            type="text"
            value={link}
            onChange={handleLinkChange}
          />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} `}
            text="Save"
            onClick={handleSaveText}
          ></Button>
        </Col>
      </Row>
      {<TextEditor text={value} onChange={setValue} />}
    </div>
  );
}
