import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useCreateOrderShipmentMutation } from "../../Data/services/orderApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/viewOrderModal.module.scss";

const ShipmentOrderModal = ({ handleEditModalClose, rowData, refetch }) => {
  const [rowId, setRowId] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });
  // const [selectedRole, setSelectedRole] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedPackageType, setSelectedPackageType] = useState("");

  // console.log(rowData);

  const serviceTypeList = [
    { label: "FEDEX_FREIGHT_PRIORITY", value: "FEDEX_FREIGHT_PRIORITY" },
    { label: "FEDEX_FREIGHT_ECONOMY", value: "FEDEX_FREIGHT_ECONOMY" },
    { label: "FEDEX_FREIGHT_PRIORITY", value: "FEDEX_FREIGHT_PRIORITY" },
  ];
  const packageTypeList = [
    { label: "BUNDLE", value: "BUNDLE" },
    { label: "CARTON", value: "CARTON" },
    { label: "CRATE", value: "CRATE" },
    { label: "DRUM", value: "DRUM" },
    { label: "PAIL", value: "PAIL" },
    { label: "PALLET", value: "PALLET" },
    { label: "OTHER", value: "OTHER" },
    { label: "SKID", value: "SKID" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const handleServiceTypeChange = (value) => {
    console.log(value);
    setSelectedServiceType(value);
  };

  const handlePackageTypeChange = (value) => {
    console.log(value);
    setSelectedPackageType(value);
  };

  // MARK: CREATE SHIPMENT

  const [createShipment, { isLoading }] = useCreateOrderShipmentMutation();

  const handleShipment = async (data) => {
    data.orderId = rowData.id;
    data.length = parseInt(data.length);
    data.width = parseInt(data.width);
    data.height = parseInt(data.height);
    data.total_pieces = parseInt(data.total_pieces);
    console.log(data);

    try {
      const response = await createShipment(data);
      console.log(response);

      if (!response.data && response.error.status === 500) {
        ErrorToast(response.error.data?.message);
      }

      if (response.data.success) {
        refetch();
        setTimeout(() => {
          Toast(response.data.message, "success");
          handleEditModalClose();
        }, 2000);
      }
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      } else {
        ErrorToast(error.data?.message);
      }
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {rowData ? `Shipment Order #${rowData.id}` : ""}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <Form onSubmit={handleSubmit(handleShipment)} className="w-100">
            <div className="w-100">
              <Row className="w-100">
                <Col lg={6}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Total Pieces
                        {errors?.total_pieces?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.total_pieces?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="total_pieces"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter Total Pieces"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Parcel width
                        {errors?.width?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.width?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="width"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter parcel width"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Parcel Height
                        {errors?.height?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.height?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="height"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter product height"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Parcel Length
                        {errors?.length?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.length?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="length"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter parcel length"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Description
                        {errors?.description?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.description?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="description"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0">
                        Sub Packaging Type
                        {errors?.subPackagingType?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.subPackagingType?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="subPackagingType"
                        // defaultValue={""}
                        rules={{
                          required: "Sub Packaging Type is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedPackageType)
                                ? "Sub Packaging Type"
                                : selectedPackageType
                            }
                            optionsList={packageTypeList}
                            // onSelectChange={handlePackageTypeChange}
                            value={value}
                            onSelectChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0">
                        Service Type
                        {errors?.serviceType?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.serviceType?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="serviceType"
                        // defaultValue={""}
                        rules={{
                          required: "Service Type is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedServiceType)
                                ? "Service Type"
                                : selectedServiceType
                            }
                            optionsList={serviceTypeList}
                            // onSelectChange={handleServiceTypeChange}
                            value={value}
                            onSelectChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        classes={`${cssStyles.cardBtn} mt-4 `}
                        // text={
                        //   isLoading || updateLoading ? (
                        //     <BeatLoader size={10} />
                        //   ) : data ? (
                        //     "Update"
                        //   ) : (
                        //     "Save"
                        //   )
                        // }
                        text={
                          isLoading ? (
                            <BeatLoader size={10} />
                          ) : (
                            "Create Shipment"
                          )
                        }
                        // onClick={handleSubmit}
                        type="submit"
                        disabled={isLoading}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ShipmentOrderModal;
