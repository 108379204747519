import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";

import {
  Button,
  CustomInput,
  Description,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import { useChangePasswordMutation } from "../../Data/services/settingsApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/ChangePassword.module.scss";

export default function ChangePassword() {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});

  // Watch the password field value to compare with newPassword
  const password = watch("password");

  // Watch the newPassword field value to compare with confirmPassword
  const newPassword = watch("newPassword");

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleDataSubmission = async (userData) => {
    console.log(userData);
    try {
      // Use unwrap() to get the response userData directly
      const response = await changePassword(userData);
      console.log(response);

      if (!response.error && response.data.success) {
        SuccessToast(response.data.message);
      }

      if (response.error) {
        Toast(response.error.data.message, "error");
      }
    } catch (error) {
      console.log(error);
      Toast(error?.data?.message, "error");
    }
  };
  return (
    <div className={styles.ChangePassword}>
      {errors
        ? errors.password?.message
          ? Toast(errors.password?.message, "error")
          : errors.newPassword?.message
          ? Toast(errors.newPassword?.message, "error")
          : errors.confirmPassword?.message
          ? Toast(errors.confirmPassword?.message, "error")
          : null
        : null}
      <SubTitle text="Change Password" classes="black-text" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4 black-text"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1 black-text" />
          <Controller
            control={control}
            name="password"
            rules={{ required: "Current Password is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1 black-text" />
          <Controller
            control={control}
            name="newPassword"
            // rules={{
            //   required: "New Password is required.",
            //   validate: (value) =>
            //     value === password ||
            //     "New Password must be different from current password.",
            // }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            // rules={{
            //   required: "Confirm Password is required.",
            //   validate: (value) =>
            //     value === newPassword ||
            //     "New Password must be match with confirm password.",
            // }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4 black-text"
          />

          <div className={styles.saveButton}>
            <Button
              text={isLoading ? <BeatLoader size={10} /> : "Change Password"}
              classes={`fs-6 mb-2 mt-auto mt-3 mb-2 ${
                isLoading ? "bg-dark-subtle" : ""
              }`}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
