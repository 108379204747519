import { createSlice } from "@reduxjs/toolkit";
import { orderApi } from "../services/orderApi";

const initialState = {
  orders: [],
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        orderApi.endpoints.getAllOrders.matchFulfilled,
        (state, { payload }) => {
          state.orders = payload.data;
        }
      )
      .addMatcher(orderApi.endpoints.getAllOrders.matchRejected, (state) => {
        state.orders = null;
      });
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } = orderSlice.actions;

export default orderSlice.reducer;
