import { createSlice } from "@reduxjs/toolkit";
import { categoryApi } from "../services/categoryApi";

const initialState = {
  categories: [],
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        categoryApi.endpoints.getAllCategories.matchFulfilled,
        (state, { payload }) => {
          state.categories = payload.data;
        }
      )
      .addMatcher(
        categoryApi.endpoints.getAllCategories.matchRejected,
        (state) => {
          state.categories = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  categorySlice.actions;

export default categorySlice.reducer;
