import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, INITIAL_TOKEN } from "../../config/config";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("token", INITIAL_TOKEN);
      //   let auth_token = getToken();
      //   if (auth_token) {
      //     headers.set("authorization", auth_token);
      //   }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
