import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";

import AccountLayout from "../../Components/Layouts/AccountLayout";
import { storeToken } from "../../Data/services/localStorageService";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";

export default function Login({ setPage }) {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleRegisterClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  const [loginUser, { isError, isLoading, isSuccess }] = useLoginUserMutation();

  const handleLogin = async (data) => {
    console.log("handle Login");

    data.deviceType = "android";
    data.deviceToken = "weirioewruoewruowidsnfsk";

    console.log(data);

    // storeToken("testingToken");
    // navigate(WEB_ROUTES.DASHBOARD);

    try {
      const response = await loginUser(data);

      // console.log(response.error.status);
      // console.log(response);

      if (response.error && response.error.status >= 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.success === true) {
        const token = response.data.token;

        console.log(token);
        storeToken(token);
        SuccessToast(response?.data?.message);
        navigate("dashboard");
      }
    } catch (error) {
      console.log(error);
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout>
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : errors?.password?.message
          ? Toast(errors?.password?.message, "error")
          : null
        : ""}
      <SubTitle text="Vendors Panel" classes="mb-2 primary-text" />

      <Form onSubmit={handleSubmit(handleLogin)} className="w-100">
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email is required.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup className="">
              <Input
                placeholder="Email"
                type="email"
                onChange={onChange}
                value={value

                }
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Password is required.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup className="">
              <Input
                type="password"
                placeholder="Password"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />
        <Button
          text={isLoading ? <BeatLoader size={10} /> : "Login"}
          classes={`mt-3 mb-2 ${isLoading ? "bg-dark-subtle" : ""}`}
          type="submit"
          // onClick={handleSubmit}
          disabled={isLoading}
        />
      </Form>
      <div className="d-flex flex-row justify-content-between w-100 wrap">
        <span
          onClick={handleRegisterClick}
          className=" cursor-pointer primary-text"
        >
          Become a Vendor?
        </span>

        <span
          onClick={handleForgotPasswordClick}
          className=" cursor-pointer primary-text"
        >
          Forgot Password?
        </span>
      </div>
    </AccountLayout>
  );
}
