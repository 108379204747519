export const ROUTES = {
  // EDIT_PROFILE: 'EDIT_PROFILE',
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
  DISCLAIMER: "DISCLAIMER",
  COOKIE_POLICY: "COOKIE_POLICY",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_CONDITIONS: "TERMS_CONDITIONS",
  LICENSE_AGREEMENT: "LICENSE_AGREEMENT",
  ABOUT_US: "ABOUT_US",
  REFUND_POLICY: "REFUND_POLICY",
  CANCELLATION_POLICY: "CANCELLATION_POLICY",

  CONTACT_US: "CONTACT_US",
};
